// @flow
import React from "react";
import List from "../../../page-components/collection/list";
import { Configure } from "react-instantsearch-dom";
import { Layout } from "../../../components";

const FILTERS = "isPublished:false";

const Component = ({ ...rest }: any) => {
  return (
    <Layout>
      <List
        filters={FILTERS}
        {...rest}
        title={"Collection"}
        description="Unpublished works"
      />
    </Layout>
  );
};

Component.propTypes = {};
Component.defaultProps = {};

export default Component;
